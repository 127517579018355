import React from 'react';
import PropTypes from 'prop-types';
import { ContentContainer, Title, TextBlock } from '@lxlabs/change-ad-components';
import Graphic from './graphic';

const KeyMessage = ({
  graphic, title, text, children, titleSize, backgroundColor, spacing,
}) => (
  <ContentContainer halfWidth backgroundColor={backgroundColor || undefined} padding={spacing}>
    {graphic && <Graphic name={graphic} />}
    <Title alignment="center" size={titleSize}>
      {title}
    </Title>
    {(text || children) && (
      <TextBlock spacing={{ top: 'medium' }} alignment="center">
        {text || children}
      </TextBlock>
    )}
  </ContentContainer>
);

KeyMessage.propTypes = {
  graphic: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  titleSize: PropTypes.string,
  backgroundColor: PropTypes.string,
  spacing: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
  }),
};

KeyMessage.defaultProps = {
  graphic: '',
  title: '',
  text: '',
  children: null,
  titleSize: 'medium',
  backgroundColor: '',
  spacing: {},
};

export default KeyMessage;
